export const form = [
    {
        component: 'input',
        key: 'reason',
        type: 'textarea',
        label: '申请事由',
        placeholder: '请输入申请事由',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请输入申请事由'}
            },
        ]
    },
    {
        component: 'input',
        key: 'people_count',
        type: 'number',
        label: '来访人数',
        placeholder: '请输入来访人数',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请输入来访人数'}
            },
        ]
    },
    {
        component: 'select',
        key: 'date',
        mode: 'date',
        label: '来访日期',
        placeholder: '请选择来访日期',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请选择来访日期'}
            },
        ]
    },
    {
        component: 'input',
        key: 'remark',
        type: 'textarea',
        label: '备注',
        placeholder: '请输入',
    },
]