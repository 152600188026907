<template>
    <div class="temp-area">
        <div class="temp-top">
            <div class="add-temp">
                <div class="add-temp-icon" @click="addTemp"></div>
                <div class="add-temp-text">添加应用</div>
            </div>
        </div>
        <div class="temp-type-name">所有应用</div>
        <div class="temp-types">
            <ul class="temp-list" v-if="list && list.length > 0">
                <li class="temp-item" @click="showTempDetail(temp)" v-for="temp in list" :key="temp.id">
                    <img :src="base + temp.icon" class="temp-icon">
                    <div class="temp-name">{{temp.name}}</div>
                    <a-space class="temp-btn" @click.stop="delTemp(temp)" v-if="!cannotDelete(temp)">
                        <div class="txt-btn">删除</div>
                    </a-space>
                </li>
            </ul>
            <a-empty :image="simpleImage" description="暂无应用" v-else-if="list"/>
            <div class="temp-loading" v-if="loading">
                <a-icon type="loading" />
            </div>
        </div>
    </div>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import {clone} from "../../common/js/tool";
    import {base} from "../../components/icon-select";
    import {checkAdminPermission} from "../../common/constant/org";
    import {getUserWxId} from "../../common/js/storage";
    import {unDeleteTempTypes} from "../../common/constant/template";

    export default {
        name: "TemplateList",
        data() {
            return {
                list: null,
                base,
                unDeleteTempTypes,
                simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
                loading: false
            }
        },
        computed: {
            update() {
                return this.$store.getters.update;
            },
        },
        watch: {
            '$route.name': { // 解决多路由复用同一组件页面不刷新问题
                handler(val) {
                    if (val == "VolunteerTemplateList") {
                        this.is_volunteer = true; // 志工应用标识
                        this.router_prefix = "Volunteer"; // 志工路由前缀
                    } else {
                        this.is_volunteer = false;
                        this.router_prefix = "";
                    }
                    this.getTemps();
                },
                immediate: true,
            },
            update(val) {
                if(val && val.type == 'temp') {
                    this.getTemps();
                }
            },
        },
        created() {
            // this.getTemps();
        },
        methods: {
            cannotDelete(item) {
                return unDeleteTempTypes.indexOf(item.type) >= 0 || item.is_oa == 3;
            },
            addTemp() {
                this.$router.push({name: `${this.router_prefix}TemplateAdd`});
            },
            showTempDetail(temp) {
                const obj = clone(temp);
                const type = 'temp';
                this.$store.commit("setDetail", {type: type, obj: obj});
                let route = null;
                switch (temp.type) {
                    case "announcement":
                        route = {name: 'List', params: { type: "notice" }};
                        break;
                    case 25:
                        route = {name: 'CheckinHome'};
                        break;
                    case 29:
                        route = {name: 'FileNotice', params: { id: obj.id }};
                        break;
                    case 30:
                        route = {name: 'TeamLeaderLog', params: { id: obj.id }};
                        break;
                    case 42:
                        route = {name: 'VolunteerTemplateSetting', params: { id: obj.id }};
                        break;
                    default:
                        route = {
                            name: `${this.router_prefix}TemplateData`,
                            params: {sys: this.$route.params.sys, id: obj.id},
                        };
                }
                if(route) {
                    this.$router.push(route);
                }
            },
            delTemp(temp) {
                this.$confirm({
                    title: '提示',
                    content: '应用删除之后不可恢复，确定删除此应用吗？',
                    onOk: () => {
                        let url = `/admin/apply-temp/${temp.id}`;
                        this.$axios({
                            url,
                            method: 'DELETE'
                        }).then(() => {
                            this.$message.success('已删除');
                            this.getTemps();
                        })
                    }
                });
            },
            getTemps() {
                this.loading = true;
                let url = "/admin/apply-temp?pageSize=1000&sort=order"
                if (this.is_volunteer) {
                    url += "&filter[is_oa]=5";
                } else {
                    url += '&filter[or][0][is_oa]=1&filter[or][1][is_oa]=3';
                }
                if(!checkAdminPermission()) {
                    url += `&filter[and][][visible_ids][fis]=${getUserWxId()}`
                }
                this.$axios(url).then(res => {
                    this.list = res.data;
                }).finally(() => this.loading = false)
            }
        }
    }
</script>

<style scoped lang="less">
    .temp-area {
        flex: none;
        margin: 0 auto;
        width: 1200px;
        padding: 0 0 20px;
    }
    .temp-top,
    .temp-types {
        padding: 20px;
        background: #FDFCF6;
        box-shadow: 0 2px 8px rgba(255, 140, 0, 0.1);
        border-radius: 9px;
    }
    .add-temp-icon {
        position: relative;
        width: 64px;
        height: 64px;
        border-radius: 100%;
        border: 4px solid #FFCE7E;
        cursor: pointer;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border-radius: 4px;
            background-color: #FFCE7E;
        }
        &::before {
            content: "";
            width: 4px;
            height: 38px;
        }
        &::after {
            content: "";
            width: 38px;
            height: 4px;
        }
    }
    .add-temp-text {
        margin-top: 10px;
        width: 64px;
        text-align: center;
    }
    .temp-type-name {
        margin-top: 16px;
        padding: 0 16px;
    }
    .temp-types {
        margin-top: 12px;
    }
    .temp-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
    }
    .temp-item {
        display: flex;
        align-items: center;
        position: relative;
        margin: 10px 12px;
        width: 272px;
        padding: 0 8px;
        height: 64px;
        border: var(--border);
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            background-color: @background-color-base;
            .temp-btn {
                display: flex;
            }
        }
    }
    .temp-icon {
        width: 54px;
        height: 54px;
        object-fit: contain;
    }
    .temp-name {
        margin-left: 12px;
    }
    .temp-btn {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 16px;
        margin: auto 0;
        &:hover {
            .txt-btn {
                text-decoration: underline;
            }
        }
    }
    .temp-loading {
        text-align: center;
        color: #FFCE7E;
        font-size: 24px;
    }
</style>
