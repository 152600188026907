<template>
  <router-view></router-view>
</template>

<script>
  import {form as actForm} from "../../common/act/form";
  import {form as recForm} from "../../common/rec/form";
  import {getTempMenu} from "../../common/constant/template";

  export default {
  name: "Template",
  data() {
    return {
      info: null
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  watch: {
    id() {
      this.getTemp();
    }
  },
  created() {
    this.getTemp();
  },
  beforeRouteLeave(to, from, next) {
    // 跳转到不是模板子菜单下面的页面 清空子菜单
    if(!(to.name == 'List' && ['holiday', 'place', 'room', 'car', 'box'].indexOf(to.params.type) >= 0)) {
      this.$store.commit("setSubMenus", null);
    }
    next();
  },
  methods: {
    getTemp() {
      let detail = this.$store.getters.detail;
      let id = this.id;
      if(detail && detail.type == 'temp' && detail.obj.id == id) {
        this.dealInfo(detail.obj)
      } else {
        if(id == 'act' || id == 'rec') {
          this.getSpecialTemp();
        } else {
          this.getDetail();
        }
      }
    },
    getDetail() {
      let url = `/admin/apply-temp/${this.$route.params.id}`;
      this.$axios(url).then(this.dealInfo);
    },
    getSpecialTemp() {
      let id = this.$route.params.id;
      let type = id == 'act' ? 4 : 3;
      let url = `/admin/apply-temp?filter[is_oa]=${type}`;
      this.$axios(url).then(res => {
        if(res.data && res.data.length > 0) {
          let temp = res.data[0];
          this.dealInfo(temp)
        } else {
          let data = {
            name: id == 'act' ? '大型活动' : '接待',
            detail: JSON.stringify(id == 'act' ? actForm : recForm),
            is_oa: type
          }
          this.$axios({
            url: '/admin/apply-temp',
            method: 'POST',
            data
          }).then(res => {
            this.dealInfo(res);
          })
        }
      })
    },
    dealInfo(info) {
      this.$store.commit("setSubMenus", getTempMenu(info));
      this.$store.commit("setTemp", info);
      this.info = info;
    },
  }
}
</script>

<style scoped>

</style>
