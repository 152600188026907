export const form = [
    {
        component: 'input',
        key: 'theme',
        type: 'text',
        label: '活动主题',
        placeholder: '请输入活动主题',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请输入活动主题'}
            },
        ]
    },
    {
        component: 'select',
        key: 'begin_time',
        mode: 'date',
        label: '开始时间',
        placeholder: '请选择开始时间',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请选择开始时间'}
            },
        ]
    },
    {
        component: 'select',
        key: 'end_time',
        mode: 'date',
        label: '结束时间',
        placeholder: '请选择结束时间',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请选择结束时间'}
            },
        ]
    },
    {
        component: 'book',
        key: 'range',
        mode: 'date',
        label: '活动范围',
        multiple: true,
        type: "mix",
        placeholder: '请选择活动范围',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请选择活动范围'}
            },
        ]
    },
    {
        component: 'input',
        key: 'program',
        type: 'textarea',
        label: '活动方案',
        placeholder: '请输入活动方案',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请输入活动方案'}
            },
        ]
    },
    {
        component: 'book',
        key: 'assign',
        mode: 'date',
        label: '计划指派',
        multiple: true,
        type: "user",
        placeholder: '请选择计划指派人',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请选择计划指派人'}
            },
        ]
    },
    {
        component: 'upload',
        key: 'attach',
        label: '附件',
        limit: 9,
    },
]