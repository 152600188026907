<template>
  <div class="content md temp-add" id="template">
    <div class="temp-add-steps">
      <a-steps :current="step">
        <a-step :title="step.title" v-for="(step, i) in steps" :key="i" />
      </a-steps>
    </div>
    <div class="temp-add-area">
      <form-area
          ref="tempForm"
          class="temp-form"
          layout="horizontal"
          :label-col="{span: 4}"
          :wrapper-col="{span: 20}"
          :items="tempItems"
          :entity="form"
          hide-btn
          v-show="step == 0"></form-area>
      <custom-form
          class="form-edit"
          ref="formEdit"
          hide-btn
          :list="formControls"
          :edit.sync="edit"
          v-show="step == 1"></custom-form>
      <div class="temp-rule" v-show="step == 2">
        <div class="temp-rule-item">
          <div class="temp-rule-label">审批流程</div>
          <div class="temp-rule-ctx">
            <a-button @click="setProcess">设置</a-button>
            <div class="temp-process"></div>
          </div>
        </div>
        <div class="temp-rule-item">
          <div class="temp-rule-label">可见范围</div>
          <div class="temp-rule-ctx">
            <select-book multiple type="book" v-model="original_visible_ids"></select-book>
          </div>
        </div>
        <div class="temp-rule-item">
          <div class="temp-rule-label">管理员</div>
          <div class="temp-rule-ctx">
            <select-book multiple type="book" v-model="original_admin_ids"></select-book>
          </div>
        </div>
      </div>
    </div>
    <a-space class="footer">
      <a-button @click="prev" v-show="step > 0">上一步</a-button>
      <a-button @click="next" v-show="step < steps.length - 1">下一步</a-button>
      <a-button type="primary" @click="confirm" v-show="step == steps.length - 1">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import customForm from "../../components/custom-form/custom-form";
import {getVolunteerTempFormItems, tempItems} from "../../common/constant/template";
import {clone, isEmpty} from "../../common/js/tool";

export default {
  name: "TemplateAdd",
  components: {
    customForm
  },
  data() {
    return {
      formControls: [],
      edit: true,
      step: 0,
      steps: [
        {title: '应用设置'},
        {title: '表单配置'},
        {title: '其他设置'},
      ],
      form: {},
      original_visible_ids: [],
      original_admin_ids: [],
    }
  },
  computed: {
    is_volunteer() {
      return this.$route.name == "VolunteerTemplateAdd";
    },
    router_prefix() {
      return this.is_volunteer ? 'Volunteer' : '';
    },
    tempItems() {
      let items = clone(tempItems);
      if (this.is_volunteer) {
        // 志工应用，去除应用类别，更换分组选项
        getVolunteerTempFormItems(items);
      }
      return items;
    },
  },
  methods: {
    prev() {
      if(this.step == 0) return;
      this.step -= 1;
    },
    next() {
      let step = this.step;
      if(step == this.steps.length - 1) return;
      if(step == 0) {
        this.$refs.tempForm.handleConfirm().then(form => {
          this.form = form;
          this.step += 1;
        });
      } else if(step == 1) {
        this.$refs.formEdit.saveForm().then(form => {
          this.form.detail = form;
          this.step += 1;
        })
      }
    },
    setProcess() {
      this.$confirm({
        title: '提示',
        content: '设置流程前，需先保存当前应用的设置',
        okText: '保存并继续',
        cancelText: '留在此页',
        onOk: () => {
          this.confirm();
        }
      })
    },
    confirm() {
      const form = this.form;
      if(!form.name || !form.icon || (!this.is_volunteer && isEmpty(form.type))) { // 志工忽略type字段
        this.$message.warning('请完善应用设置');
        return;
      }
      if(!form.detail) {
        this.$message.warning('请配置应用表单');
        return;
      }
      let {original_visible_ids, original_admin_ids} = this;
      if(!original_visible_ids || original_visible_ids.length <= 0) {
        this.$message.error("请设置可见范围");
        return;
      }
      if(!original_admin_ids || original_admin_ids.length <= 0) {
        this.$message.error("请设置管理员");
        return;
      }
      let visible_ids = {
        uids: [],
        dept: [],
        tag: []
      };
      original_visible_ids.forEach(item => {
        if(item.type == "department") {
            visible_ids.dept.push(item.id);
        } else if(item.type == 'user') {
            visible_ids.uids.push(item.id);
        } else if(item.type == 'tag') {
            visible_ids.tag.push(item.id);
        }
      });
      let admin_ids = {
        uids: [],
        dept: [],
        tag: []
      }
      original_admin_ids.forEach(item => {
        if(item.type == "department") {
            admin_ids.dept.push(item.id);
        } else if(item.type == 'user') {
            admin_ids.uids.push(item.id);
        } else if(item.type == 'tag') {
            admin_ids.tag.push(item.id);
        }
      });
      form.original_visible_ids = JSON.stringify(original_visible_ids);
      form.visible_ids = visible_ids;
      form.original_admin_ids = JSON.stringify(original_admin_ids);
      form.admin_ids = admin_ids;
      if (this.is_volunteer) {
        form.is_oa = 5; // 志工应用
      }
      let url = `/admin/apply-temp`;
      this.$axios({
        url,
        method: 'POST',
        data: {
          ...form
        }
      }).then(res => {
        this.$store.commit("setDetail", {type: 'temp', obj: res});
        this.$router.replace({name: `${this.router_prefix}Process`, params: {id: res.id}});
      })
    },
  }
}
</script>

<style scoped lang="less">
.temp-add {
  display: flex;
  flex-direction: column;
  padding: 20px 40px 0;
}
.temp-add-steps {
  margin: 0 100px;
}
.temp-add-area {
  flex: 1;
  margin-top: 12px;
}
.temp-form {
  margin: 0 auto;
  width: 600px;
  min-height: 100%;
}
.temp-rule {
  padding: 20px 100px;
}
.temp-rule-item {
  display: flex;
  margin-top: 12px;
  height: 36px;
  line-height: 36px;
}
.temp-rule-label {
  width: 100px;
}
.temp-rule-ctx {
  flex: 1;
}
</style>
